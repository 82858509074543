export const navMon = [
  {
    name: "Бидний тухай",
    engName: "About Us",
    id: 2,
    content: [
      {
        title: "Бидний тухай",
        context: [
          {
            subName: "",
            text: "Австрали дахь Монголчуудын нийгмийн үйлчилгээ, хөгжлийн нийгэмлэг (Aus-Mon Community services & Development inc.) нь 2020 оны 10-р сард байгуулагдсан, Австрали улсад бүртгэлтэй, ашгийн бус байгууллага юм. Тус байгууллага нь Австрали дахь Монголчуудын эрүүл мэнд, нийгмийн сайн сайхан байдлыг дэмжих, Монгол хэл, соёлыг Автрали дахь хүүхэд, залуусын дунд суртачилан таниулах зорилготой. Хэл, соёлын ялгаатай байдлын улмаас эмнэлэг, сургууль зэрэг байгууллагуудаас хүссэн үйлчилгээгээ авч чаддахгүй үе бидэнд  тохиолддог. Мөн бэрхшээл тулгарахад хаана, хэнд хандахаа мэдэхгүй байх тохиолдол олонтаа байдаг билээ. Тиймээс бид энэхүү асуудлыг шийдвэрлэх, даван туулах арга замыг эрэлхийлэх, Австралийн холбогдох байгууллагуудтай холбох гүүр нь болж ажиллахыг зорьж байна.",
          },
        ],
      },
      {
        title: "ҮҮСГЭН БАЙГУУЛАГЧДЫН ЗУРВАС",
        context: [
          {
            subName: "",
            image: ["founders.jpeg"],
            text: "Урт хугацаанд үргэлжилж буй Ковид-19-ийн нөхцөл байдал бидэнд ихээхэн зүйлийг ухааруулж ойлгуулсан билээ. Үүний нэг нь бид бүгд бие биеэсээ хамааралтай, нийгмийн нэг хэсэг бөгөөд хүнд хэцүү цаг үед бусдын тусламжийг авч, эргээд тэдэндээ туслахын ач холбогдлыг эргэн санацгаасан явдал байв. Өнгөрсөн жилийн цаг үеийн нөхцөл байдал бид бүгдийг өөрсдийн хүсч, хийхийг зорьж буй зүйлээ албажуулан энэхүү байгууллагыг үүсгэн байгуулахад ихээхэн түлхэц болсон юм. Байгууллагаа албан ёсоор Австрали улсад бүртгүүлэн, бэлтгэл ажлаа хийж, үйл ажиллагаа явуулж эхэлснээр хэдэн сарыг ардаа үджээ. Та бүхэндээ үйл ажиллагаагаа улам ойртуулахаар зорьж, энэхүү сар бүрийн мэдээллийн эмхтгэлийн эхний дугаараараа уулзаж байгаадаа таатай байна. Бид та бүхний санаа бодлыг сонсон, үйл ажиллагааныхаа хөтөлбөрт тусгаж, аливаа хамтран ажиллах санал, санаачлагыг дуртайяа хүлээн авах болно.",
          },
        ],
      },
      {
        title: "БИДНИЙ ЯВУУЛЖ БУЙ ЗАРИМ ҮЙЛ АЖИЛЛАГАА:",
        context: [
          {
            subName: "Мэдээллийн өдөрлөгүүд",
            text: "Австрали улсад амьдарч буй Монголчууддаа эх хэл дээрээ хэрэгцээт мэдээллийг авах боломжийг олгохын тулд Австрали улсын засгийн газрын болон бусад байгууллагуудтай хамтран ажиллаж, мэдээлэл түгээж байна. Өнгөрсөн хугацаанд хүүхдээ цэцэрлэг болон сургуульд ороход хэрхэн бэлдэх, эрүүл, тэнцвэртэй хооллолт, Цагдаагийн газар, Service NSW -ийн үйлчилгээний мэдээлэл зэрэг мэдээллийн өдөрлөгүүдийг зохион байгуулсан. Цаашид мэдээллийн хэрэгцээ шаардлагын судалгаа дээр үндэслэн мэдээллийн өдөрлөг тогтсол үргэлжилнэ.",
          },
          {
            subName: "Англи хэлний ярианы хичээл",
            text: "Онлайн Англи хэлний хичээл болон ярианы анги хичээллүүлдэг ба Ковид-19-ийн тархалтын улмаас уг хөтөлбөр түр завсарласан байгаа билээ. Уг хөтөлбөр удахгүй үргэлжлэн хичээллэнэ.",
          },
          {
            subName: "Бямба гарагийн чөлөөт үйл ажиллагаа",
            text: "Бямба гарагийн Англи хэлний ярианы хичээлийн дараа үргэлжлүүлэн ээжүүд болон хүүхдүүдэд зориулсан чөлөөт үйл ажиллагаа явуулдаг. уг хөтөлбөр мөн Ковид - 19-ийн тархалтын улмаас түр завсарласан ба хоёр долоо хоног бүрийн Мягмар гарагийн Ээжүүдийн хөтөлбөрт хэсэгчлэн  тусгагдан хэрэгжиж байна.",
          },
          {
            subName: "Хэрэгцээт мэдээллийг хүргэх",
            text: "Монголчуудад зориулсан хэрэгцээт мэдээллийг байгууллагынхаа хуудсаараа дамжуулан хүргэсээр байна.",
          },
          {
            subName: "Хүнс болон санхүүгийн тусламжид хамруулах",
            text: "Ковид-19 цар тахлын үеэр 300 гаруй гэр бүлийг хүнсний болон санхүүгийн тусламжид хамруулсан. Мөн жил бүр Когарахсторхаустай хамтран Монгол хүүхдүүддээ шинэ жилийн бэлэг тараах үйл ажиллагааг зохион байгуулдаг. Энэхүү хүнсний тусламжид хамрагдах бол холбоо барих хэсэгт байгаа мэдээллээр бидэнтэй холбогдоорой.",
            image: ["moms.jpeg", "moms1.jpeg"],
          },
          {
            subName: "Ээжүүдэд зориулсан хөтөлбөр",
            text: "Манай байгууллагын зүгээс South Eastern Sydney Local Health District-ийн мэргэжлийн эмч, сургагч багш нартай хамтран жирэмсэн болон төрсний дараа ээжүүд болон эмэгтэйчүүдэд тохиолддог сэтгэл зүйн асуудлуудын талаар мэдээлэл олгох, холбогдох үйлчилгээг танилцуулах цуврал уулзалтуудыг зохион байгуулж байгаа билээ. Энэхүү хөтөлбөр нь 2022 оны 02-р сарын 8-аас 10 -р сарын 25-ы хооронд үргэлжлэх ба хоёр долоо хоног бүрийн Мягмар гарагт зохион байгуулагдаж байна. Уулзалт бүрийн дараа Ээжүүддээ зориулсан сонирхолтой төрөл бүрийн хөтөлбөртэй ба энэ хөтөлбөрт хүүхдүүдээ дагуулж ирээд оролцох боломжтой. Бидэнтэй хамтран ажиллаж буй хүүхэдтэй ажиллах эрхтэй багш нар хүүхэд харах ба Монгол дуу, шүлэг заах зэрэг хүүхдийн хөгжилд чиглэсэн хөтөлбөртэй. Мэдээллийг 0420 663 018 болон 0415 674 556 дугаараас лавлаарай.",
          },
          {
            subName: "PARTNERS/ БИДНИЙ ХАМТРАН АЖИЛЛАЖ БУЙ БАЙГУУЛЛАГУУД: ",
            text: "",
            list: [
              "The Kogarah Storehouse",
              "Advance Diversity Services",
              "Rockdale Family Hub - Better Connected Community Services Inc.",
              "South Eastern Sydney LHD",
              "St George Area Police",
              "Bayside Council ",
              "Safe Haven",
              "Marrickville Legal Centre",
              "Diabetes NSW & ACT",
              "St George & Sutherland Mental Health Service",
              "Better Connected",
              "Munch and Move",
              "Early Childhood Support team - Cross culture",
              "Women’s Health Clinics at Rockdale",
              "Plumtree",
              "Early childhood - Children and family program",
              "Early Childhood - Early parenting program",
            ],
          },
        ],
      },
    ],
  },
  { name: "Мэдээ", engName: "News", id: 3 },
  { name: "Өдөрлөгүүд", engName: "Events", id: 4 },
  { name: "Зургын цомог", engName: "Album", id: 5 },
  { name: "Үйл ажиллагааны чиглэлүүд", engName: "Services", id: 6 },
  { name: "Сайн дурын ажиллагаа", engName: "Volunteer", id: 7 },
  {
    name: "Холбоо Барих",
    engName: "Contact Us",
    id: 8,
    content: {
      phone: "0415 674 556, 0451 441 085",
      email: "ausmon.csdi@gmail.com",
      socialMedia: "https://www.facebook.com/AMCSDI",
    },
  },
];
