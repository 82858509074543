export const navEng = [
  // { name: "Home", id: 1 },
  { engName: "About Us", name: "About Us", id: 2 },
  { engName: "News", name: "News", id: 3 },
  { engName: "Events", name: "Events", id: 4 },
  { engName: "Album", name: "Album", id: 5 },
  { engName: "Activities", name: "Activities", id: 6 },
  { engName: "Volunteer", name: "Volunteer", id: 7 },
  { engName: "Contact Us", name: "Contact Us", id: 8 },
];
